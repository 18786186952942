<template lang="pug">
div(id='top')
  ClientOnly
    AgentToolbar(v-if='isIntegrationEnabled("cognito") && $cognito.isLoggedIn() && $cognito.isSalesAgent()')
  HeaderPromoBar
  HeaderLinkBar
  Header(id='Header')
  slot
  Footer(id='Footer')
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
</script>
